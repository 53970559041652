body {
    margin: 0;
    padding: 0;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 0.85rem;
    font-weight: 300;
    line-height: 1.5;
    text-align: left;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

html {
    /* Remove fixed black background */
    background-color: transparent;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
